import React from 'react'
import {components} from 'react-select'

const CustomMultiValue = (props: any) => {
    let s = props.children;
    if (props.children.length > 4) {
        s = `${props.children.substring(0, 4)}`;
    }
    return <components.MultiValue {...props}>{s}</components.MultiValue>;
};

export default CustomMultiValue;