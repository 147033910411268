import React, {FC, useEffect, useState} from 'react';
import moment from 'moment-timezone';
import axios from 'axios';
import BookingList from '../Shared/BookingList';
import {groupByCriteria} from '../Shared/Utils';
import {campus, customPlaceholder, IGroupByBooking, IProps, TBookingList, timezone} from '../Shared/Definitions';
import {useTranslation} from 'react-i18next';
import 'moment/locale/sv';
import 'moment/locale/en-gb';
import ReactPlaceholder from "react-placeholder";
import {useLocation} from 'react-router-dom';
import {useQuery} from 'react-query';

const Today: FC<IProps> = ({onProgressHandler}) => {
    const {t, i18n} = useTranslation();
    i18n.resolvedLanguage === 'en' ? moment.locale('en-gb') : moment.locale('sv');

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [time, setTime] = useState(Date.now());
    const [criteria, setCriteria] = useState<string>('start');
    const [groupByList, setGroupByList] = useState<IGroupByBooking[]>([]);
    const start = moment().tz(timezone).set({
        hour: 0,
        minute: 0,
        second: 0
    }).format('YYYY-MM-DD[T]HH:mm:ssZ');
    const end = moment().tz(timezone).set({
        hour: 23,
        minute: 59,
        second: 59
    }).format('YYYY-MM-DD[T]HH:mm:ssZ');

    const fetchBookings = () => {
        onProgressHandler(false);

        const url = `${process.env.REACT_APP_API_URL}${queryParams.get('all') === 'true' ? 'bookings-w-labs' : 'bookings'}`;
        const params = {params: {id: campus, start: start, end: end}};
        return axios.get<TBookingList>(url, params);
    };

    const onClickHandlerStart = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setCriteria('start');
    };

    const onClickHandlerName = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setCriteria('location');
    };

    const {
        isLoading,
        isError,
        data
    } = useQuery(['today', start, end], fetchBookings, {
        refetchOnMount: true,
        refetchOnWindowFocus: true
    });

    if (isError) {
        onProgressHandler(true);
    }

    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 900000);
        if (data && !isError) {
            setGroupByList(groupByCriteria(data?.data, criteria));
            onProgressHandler(true);
        }

        return () => {
            clearInterval(interval);
        };
    }, [data, onProgressHandler, criteria, isError, time]);

    return (
        <>
            <h1 className="mb-0">{t('Bokningar idag')}</h1>
            {isError && <div className="alert alert-danger" role="alert">{t('Felmeddelande')}</div>}
            <button onClick={onClickHandlerStart} type="button"
                    className={`btn btn-link no-outline ${criteria === 'start' ? 'active-sort' : ''}`}>{t('Tid')}
            </button>
            <span className="no-outline"> | </span>
            <button onClick={onClickHandlerName} type="button"
                    className={`btn btn-link no-outline ${criteria === 'name' ? 'active-sort' : ''}`}>{t('Lokal')}
            </button>
            <ReactPlaceholder showLoadingAnimation customPlaceholder={customPlaceholder} ready={!isLoading}>
                {groupByList.length === 0 ? <h5>{t('Inga bokningar idag')}</h5> :
                    <BookingList groupByList={groupByList}/>}
            </ReactPlaceholder>
        </>
    );
};

export default Today;