import React, {FC} from 'react';
import BookingListItem from './BookingListItem';
import {IBooking, IGroupByBooking} from './Definitions';
import {formatDateAsString} from './Utils';

interface IBookingListProps {
    groupByList: IGroupByBooking[];
}

const BookingList: FC<IBookingListProps> = ({groupByList}) => {

    return (
        <>
            {
                groupByList.map((groupByBooking: IGroupByBooking) => (
                    <table className="table" key={groupByBooking.criteria}>
                        <thead className="thead-light">
                        <tr>
                            <th colSpan={3}>{formatDateAsString(groupByBooking.criteria, 'dddd D MMMM YYYY')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            groupByBooking.bookings.map((booking: IBooking) =>
                                <BookingListItem key={booking.reservationId} id={booking.id}
                                                 reservationId={booking.reservationId} location={booking.location}
                                                 start={booking.start} end={booking.end} title={booking.title}/>
                            )
                        }
                        </tbody>
                    </table>
                ))
            }
        </>
    );
};

export default BookingList;