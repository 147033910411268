import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const DETECTION_OPTIONS = {
    order: ['localStorage', 'navigator'],
    caches: ['localStorage', 'cookie']
};

const RESOURCES = {
    en: {
        translation: {
            'Bokningar idag': 'Bookings today',
            'Bokningar andra dagar': 'Find bookings',
            'Hitta lokal': 'Find room',
            'Lokal': 'Room',
            'Lokalbokningar': 'Bookings',
            'Sprak': 'Svenska',
            'Visa': 'Show',
            'Ledigt i 60 min': 'Free for 60 min',
            'Ledigt i mindre an 60 min': 'Free less than 60 min',
            'Upptaget': 'Busy',
            'Platser': 'Seats:',
            'Lokalbokningar Ekonomikum': 'Bookings Ekonomikum',
            'Uppsala universitet': 'Uppsala University',
            'Hoppa till huvudinnehallet': 'Skip to main content',
            'Idag': 'Today',
            'Organisationsnummer': 'Registration number',
            'Momsregistreringsnummer': 'VAT number',
            'Telefon': 'Tel',
            'Telefonnummer': '+46 18 471 00 00',
            'Box': 'P.O. Box 256, SE-751 05 Uppsala, SWEDEN',
            'Inga bokningar idag': 'No reservations found',
            'Meny': 'Menu',
            'Tid': 'Time',
            'Kaktext': 'Uppsala University uses cookies to make your website experience as good as possible.',
            'Kakmertext': 'Read more about cookies.',
            'Kakknapp': 'Ok',
            'Kakaria': 'Message about cookies.',
            'Registrator': 'Registrar',
            'Om webbplatsen': 'About this website',
            'Dataskyddspolicy': 'Privacy policy',
            'Sidansvarig': 'Editor',
            'Felmeddelande': 'An error occurred, please try again later.',
            'Startdatum': 'Start date',
            'Slutdatum': 'End date'
        }
    },
    sv: {
        translation: {
            'Bokningar idag': 'Bokningar idag',
            'Bokningar andra dagar': 'Hitta bokningar',
            'Hitta lokal': 'Hitta lokal',
            'Lokal': 'Lokal',
            'Lokalbokningar': 'Lokalbokningar',
            'Sprak': 'English',
            'Visa': 'Visa',
            'Ledigt i 60 min': 'Ledigt i 60 min',
            'Ledigt i mindre an 60 min': 'Ledigt i mindre än 60 min',
            'Upptaget': 'Upptaget',
            'Platser': 'Platser:',
            'Lokalbokningar Ekonomikum': 'Lokalbokningar Ekonomikum',
            'Uppsala universitet': 'Uppsala universitet',
            'Hoppa till huvudinnehallet': 'Hoppa till huvudinnehållet',
            'Idag': 'Idag',
            'Organisationsnummer': 'Organisationsnummer',
            'Momsregistreringsnummer': 'Momsregistreringsnummer',
            'Telefon': 'Telefon',
            'Telefonnummer': '018-471 00 00',
            'Box': 'Box 256, 751 05 Uppsala',
            'Inga bokningar idag': 'Inga bokningar hittades',
            'Meny': 'Meny',
            'Tid': 'Tid',
            'Kaktext': 'Uppsala universitet använder kakor (cookies) för att webbplatsen ska fungera bra för dig.',
            'Kakmertext': 'Läs mer om kakor.',
            'Kakknapp': 'Ok',
            'Kakaria': 'Meddelande om kakor (cookies).',
            'Registrator': 'Registrator',
            'Om webbplatsen': 'Om webbplatsen',
            'Dataskyddspolicy': 'Dataskyddspolicy',
            'Sidansvarig': 'Sidansvarig',
            'Felmeddelande': 'Ett fel uppstod, vänligen försök igen senare.',
            'Startdatum': 'Startdatum',
            'Slutdatum': 'Slutdatum'
        }
    }
};

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        detection: DETECTION_OPTIONS,
        resources: RESOURCES,
        fallbackLng: 'en'
    });

export default i18n;