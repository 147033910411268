import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import {NavLink, useLocation} from "react-router-dom";
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

interface IProps {
    setOpen: (open: boolean) => void;
    open: boolean;
}

const Menu: FC<IProps> = ({setOpen, open}) => {
    const {pathname} = useLocation();
    const {t} = useTranslation();

    return (
        <Popup
            closeOnDocumentClick={false} open={open} modal>
            {() => (
                <div className="inner">
                    <ul className="nav">
                        <li>
                            <NavLink onClick={() => setOpen(false)} activeClassName={"nav-link:hover"}
                                     className="nav-link"
                                     to={"/bokningar-idag"}
                                     isActive={() => ['/', '/bokningar-idag'].includes(pathname)}>{t('Bokningar idag')}</NavLink>
                        </li>
                        <li>
                            <NavLink onClick={() => setOpen(false)} activeClassName={"nav-link:hover"}
                                     className="nav-link"
                                     to={"/bokningar-andra-dagar"}>{t('Bokningar andra dagar')}</NavLink>
                        </li>
                        <li>
                            <NavLink onClick={() => setOpen(false)} activeClassName={"nav-link:hover"}
                                     className="nav-link"
                                     to={"/hitta-lokal"}>{t('Hitta lokal')}</NavLink>
                        </li>
                    </ul>
                </div>
            )}
        </Popup>
    );
};

export default Menu;