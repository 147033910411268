import React, {FC, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import Menu from './Menu';
import {isKiosk} from '../containers/Shared/Utils';

const Header: FC = () => {
    const {t, i18n} = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const [kiosk, setKiosk] = useState<boolean>(false);

    useEffect(() => {
        (async () => setKiosk(await isKiosk()))();
    });

    return (
        <>
            <header className="layout-header">
                <div className="skip-links">
                    <a href="#MainContent" className="button">{t('Hoppa till huvudinnehallet')}</a>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-3 layout-header-logo">
                            {kiosk ? <>
                                <span className="is-only-visible-on-desktop-scroll-top layout-logo logo-link">
                                    <img alt="Uppsala universitets logo"
                                         src="https://static.uu.se/designsystem/latest/theme-uu/img/uu-logo-red.svg"/>
                                </span>
                                <span
                                    className="is-only-visible-on-desktop-scroll-down layout-header-name-text is-hidden"
                                >{t('Uppsala universitet')}</span>
                            </> : <>
                                <a href="https://uu.se"
                                   className="is-only-visible-on-desktop-scroll-top layout-logo logo-link">
                                    <img alt="Uppsala universitets logo"
                                         src="https://static.uu.se/designsystem/latest/theme-uu/img/uu-logo-red.svg"/>
                                </a>
                                <a className="is-only-visible-on-desktop-scroll-down layout-header-name-text is-hidden"
                                   href="https://www.uu.se">{t('Uppsala universitet')}</a>
                            </>}
                        </div>
                        <div className="col-5 layout-header-name">
                            <Link to="/" className="layout-header-name-text">{t('Lokalbokningar Ekonomikum')}</Link>
                        </div>
                        <div className="col-4 layout-header-buttons layout-header-fixed-mobile">
                            <div className="row layout-header-buttons-row is-only-visible-on-desktop-scroll-top">
                                <button className="button button-text" onClick={() =>
                                    i18n.resolvedLanguage === 'en' ? i18n.changeLanguage('sv') : i18n.changeLanguage('en')
                                }>{t('Sprak')}</button>
                                <button className="button-icon icon-menu icon-white button-text" type="button"
                                        aria-controls="navigation" aria-expanded="false"
                                        aria-label={t(`Meny`)} onClick={() => setOpen(true)}>
                                    {t('Meny')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <Menu setOpen={setOpen} open={open}/>
        </>
    );
};

export default Header;