import React, {FC, useState, useEffect} from 'react';
import {Link, NavLink, Route, Switch, useLocation} from 'react-router-dom';
import Today from '../containers/Today/Today';
import Tomorrow from '../containers/Tomorrow/Tomorrow';
import {IProps} from '../containers/Shared/Definitions';
import Find from '../containers/Find/Find';
import {useTranslation} from 'react-i18next';
import {isKiosk} from '../containers/Shared/Utils';

const Main: FC<IProps> = ({onProgressHandler}) => {
    const {pathname} = useLocation();
    const {t} = useTranslation();
    const [kiosk, setKiosk] = useState<boolean>(false);

    useEffect(() => {
        (async () => setKiosk(await isKiosk()))();
    });

    return (
        <div className="layout-main">
            <div className="container-fluid breadcrumbs">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <ol className="breadcrumb">
                                {kiosk ? <li className="breadcrumb-item">Uppsala universitet</li> :
                                    <li className="breadcrumb-item"><a href="https://uu.se/">Uppsala universitet</a>
                                    </li>}
                                <li className="breadcrumb-item"><Link to="/">{t('Lokalbokningar')}</Link></li>
                                <Switch>
                                    <Route path="/bokningar-idag">
                                        <li className="breadcrumb-item active" aria-current="page">{t('Bokningar idag')}
                                        </li>
                                    </Route>
                                    <Route path="/bokningar-andra-dagar">
                                        <li className="breadcrumb-item active"
                                            aria-current="page">{t('Bokningar andra dagar')}</li>
                                    </Route>
                                    <Route path="/hitta-lokal/:id?">
                                        <li className="breadcrumb-item active"
                                            aria-current="page">{t('Hitta lokal')}</li>
                                    </Route>
                                    <Route path="/*">
                                        <li className="breadcrumb-item active"
                                            aria-current="page">{t('Bokningar idag')}</li>
                                    </Route>
                                </Switch>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                        <div className="col-lg-3">
                            <div className="collapse" id="navigation">
                                <ul className="nav flex-column">
                                    <li>
                                        <NavLink activeClassName={"nav-link:hover"} className="nav-link"
                                                 to={"/bokningar-idag"}
                                                 isActive={() => ['/', '/bokningar-idag'].includes(pathname)}>{t('Bokningar idag')}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName={"nav-link:hover"} className="nav-link"
                                                 to={"/bokningar-andra-dagar"}>{t('Bokningar andra dagar')}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName={"nav-link:hover"} className="nav-link"
                                                 to={"/hitta-lokal"}>{t('Hitta lokal')}</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <main className="col-lg-9">
                            <span id="MainContent" tabIndex={-1}/>
                            <Switch>
                                <Route path="/bokningar-idag">
                                    <Today onProgressHandler={onProgressHandler}/>
                                </Route>
                                <Route path="/bokningar-andra-dagar">
                                    <Tomorrow onProgressHandler={onProgressHandler}/>
                                </Route>
                                <Route path="/hitta-lokal/:id?">
                                    <Find onProgressHandler={onProgressHandler}/>
                                </Route>
                                <Route path="/*">
                                    <Today onProgressHandler={onProgressHandler}/>
                                </Route>
                            </Switch>
                        </main>
                </div>
            </div>
        </div>
    );
};

export default Main;