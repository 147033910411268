// @ts-ignore
import {Mazemap} from 'mazemap';
import 'mazemap/mazemap.min.css';
import QRCode from 'qrcode';
import React, {FC, memo, useCallback, useEffect, useRef, useState} from 'react';
import axios, {AxiosError} from 'axios';
import moment from 'moment-timezone';
import 'moment/locale/sv';
import 'moment/locale/en-gb';
import {timezone} from './Definitions';
import {useTranslation} from 'react-i18next';

type TFeature = {
    properties: {
        id: any;
        name: any;
        zLevel: any;
    }
    type: 'Feature',
    geometry: {
        type: 'Polygon';
        coordinates: any;
    }
}

type TBooking = {
    id: string;
    reservationId?: string;
    createdAt?: string;
    modifiedAt?: string;
    createdBy?: string;
    modifiedBy?: string;
    activity?: string;
    customerCode?: string;
    attendees?: string;
    generalReservationText?: string;
    externalComment?: string;
    internalComment?: string;
    course?: {
        extId: string;
        details: {
            groupId: string;
            nameSv: string;
            nameEn: string;
            groupNameSv: string;
            groupNameEn: string;
            anmkod: string;
            kurskod: string;
            termin: string;
            institution: string;
            startWeek: number;
            endWeek: number;
            generalIdReference: string;
        }
    },
    room?: {
        id: string;
        details: {
            size: number;
            url: string;
            nameSv: string;
            nameEn: string;
        }
    },
    title: string;
    start: string;
    end: string;
    identifier(): string;
}

type TPoi = {
    properties: {
        identifier: string;
        names: string[];
        zLevel: string;
        floorName: string;
        title: string;
    },
    geometry: {
        type: 'Polygon';
        coordinates: any;
    }
}

interface IProps {
    onProgressHandler: (hasFinished: boolean) => void;
    poiIdentifier?: string;
}

const MazeMapWrapper: FC<IProps> = ({onProgressHandler, poiIdentifier}) => {
    const {t, i18n} = useTranslation();
    i18n.resolvedLanguage === 'en' ? moment.locale('en-gb') : moment.locale('sv');

    const CAMPUS_ID = 49; // Uppsala universitet
    const BUILDING_ID = 858; // Segerstedthuset 875 Ekonommikum 858
    const POI_LABEL_LAYER_ID = 'mm-poi-label';
    const POI_TYPE_FILTER = ['Grupprum', 'Lärosal', 'Seminarierum', 'Sammanträdesrum', 'Mötesrum', 'Konferensrum', 'Föreläsningssal', 'Hörsal'];
    const BUSY_FEATURES: TFeature[] = [];
    const SEMI_FREE_FEATURES: TFeature[] = [];
    const FREE_FEATURES: TFeature[] = [];
    const EXCHANGE_ROOMS = ['A311', 'H319', 'K312', 'L339'];
    const BOUNDS_SW = [17.618263285792835, 59.85875735013684];
    const BOUNDS_NE = [17.62175737123461, 59.86021379489469];
    const JUMP_TO_CENTER = {
        lng: 17.6199300255212,
        lat: 59.859345263379
    };
    const JUMP_TO_ZOOM = 18;
    const [isError, setIsError] = useState(false);

    const fetchPois = (campusId: number, buildingId: number, zLevel: number = 1): Promise<TPoi[]> => {
        return Mazemap.Data.getPois({
            campusid: campusId,
            buildingid: buildingId,
            zLevel: zLevel
        });
    };

    const renderFreeBusy = async (map: Mazemap.Map, pois: any): Promise<void> => {
        const filteredPois = filterPois(map, pois);
        try {
            onProgressHandler(false);
            const results = await Promise.all(fetchBookings(filteredPois));
            results.forEach(response => mapFeatures(response, filteredPois));
            redrawPolygons(map);
        } catch (e) {
            setIsError(true);
        } finally {
            onProgressHandler(true);
        }
    };

    const mapFeatures = (response: any, filteredPois: TPoi[]) => {
        try {
            const {data = []}: { data: any[] | undefined } = response;
            if (data?.length === 0) {
                // We map rooms without bookings
                mapFreeFeatures(response, filteredPois);
            } else {
                // We map rooms with bookings
                mapBusyFeatures(data, filteredPois);
            }
        } catch (e) {
            // Ignore
        }
    };

    const mapFreeFeatures = (response: any, filteredPois: TPoi[]) => {
        const id = response.config.params.id;
        if (id) {
            let poi = filteredPois.find((p: TPoi) => p.properties.identifier === createIdentifier(id));
            if (poi !== undefined && poi.geometry.coordinates.length === 1) {
                let feature = createFeature(poi);
                if (FREE_FEATURES.findIndex(f => f.properties.id === feature.properties.id) === -1) {
                    FREE_FEATURES.push(feature);
                }
            }
        }
    };

    const mapBusyFeatures = (data: any[] | undefined, filteredPois: TPoi[]) => {
        data?.forEach((booking: TBooking) => {
            let poi = filteredPois.find((p: TPoi) => p.properties.identifier === createIdentifier(booking.id));
            if (poi === undefined) {
                //console.log(`${booking.id} ${JSON.stringify(poi)}`);
            }

            if (poi !== undefined && poi.geometry.coordinates.length !== 1) {
                //console.log(`${booking.id} ${JSON.stringify(poi)}`);
            }

            if (poi !== undefined && poi.geometry.coordinates.length === 1) {
                const m = moment().tz(timezone);

                const diff = Math.round(moment.duration(moment(booking.start).tz(timezone).diff(m)).asMinutes());
                let feature = createFeature(poi);
                if (diff === 0 || m.isBetween(moment(booking.start).tz(timezone), moment(booking.end).tz(timezone).add(1, 'm'))) {
                    if (BUSY_FEATURES.findIndex(f => f.properties.id === feature.properties.id) === -1) {
                        BUSY_FEATURES.push(feature);
                    }
                } else if (diff > 0 && diff < 60) {
                    if (SEMI_FREE_FEATURES.findIndex(f => f.properties.id === feature.properties.id) === -1) {
                        SEMI_FREE_FEATURES.push(feature);
                    }
                } else {
                    if (FREE_FEATURES.findIndex(f => f.properties.id === feature.properties.id) === -1) {
                        FREE_FEATURES.push(feature);
                    }
                }
            }
        });

        filteredPois.forEach((poi: TPoi) => {
            if (poi !== undefined && poi.geometry.coordinates.length === 1) {
                let feature = createFeature(poi);
                if (!data?.some(b => b.id === createIdentifierInverse(poi.properties.identifier))) {
                    FREE_FEATURES.push(feature);
                }
            }
        });
    };

    const createFeature = (poi: TPoi): any => {
        return {
            type: 'Feature',
            properties: {
                id: poi.properties.identifier,
                zLevel: poi.properties.zLevel,
                name: poi.properties.title
            },
            geometry: {
                type: 'Polygon',
                coordinates: poi.geometry.coordinates
            }
        }
    };

    const fetchBookings = (filteredPois: TPoi[]): any[] => {
        const start = moment().tz(timezone).set({
            hour: 0,
            minute: 0,
            second: 0
        }).format('YYYY-MM-DD[T]HH:mm:ssZ');
        const end = moment().tz(timezone).set({
            hour: 23,
            minute: 59,
            second: 59
        }).format('YYYY-MM-DD[T]HH:mm:ssZ');
        const promises: any[] = [];

        filteredPois.forEach((poi: TPoi) => {
            promises.push(fetchBooking(bookingType(poi), poi, start, end));
        });

        return promises;
    };

    const bookingType = (poi: TPoi) => {
        if (isExchange(poi)) {
            return 'bookings-exchange';
        }

        if (!isExchange(poi)) {
            return 'bookings-timeedit';
        }
        return 'bookings';
    };

    const fetchBooking = (method: string, poi: TPoi, start: string, end: string) => {
        const params = {params: {id: createPoiId(poi), start: start, end: end}};
        return axios.get(`${process.env.REACT_APP_API_URL}${method}`, params).catch((e: AxiosError) => {
            return Promise.reject(e.response.data.message);
        });
    };

    const createPoiId = (poi: TPoi): string => {
        let id = `Obs/${poi.properties.identifier.replace('Ekonomikum-', '')}`;
        if (isExchange(poi)) {
            id = `r_eko${poi.properties.identifier.replace('Ekonomikum-', '')}@user.uu.se`;
        }
        return id;
    };

    const isExchange = (poi: TPoi) => {
        return EXCHANGE_ROOMS.includes(poi.properties.identifier.replace('Ekonomikum-', ''));
    };

    const filterPois = (map: Mazemap.Map, pois: TPoi[]): TPoi[] => {
        return pois.filter((poi: TPoi) => {
            if (poi.properties.zLevel === map.zLevel) {
                return poi.properties.names.some(r => POI_TYPE_FILTER.includes(r));
            }
            return false;
        });
    };

    const createIdentifier = (id: string): string => {
        return `Ekonomikum-${id.replace('-', '/').replace(new RegExp('Obs/', 'i'), '')
            .replace('@user.uu.se', '').replace('r_eko', '')}`;
    };

    const createIdentifierInverse = (id: string): string => {
        if (EXCHANGE_ROOMS.some(s => s === id.replace('Ekonomikum-', ''))) {
            return `${id.replace('Ekonomikum-', 'r_eko')}@user.uu.se`;
        }

        return `${id.replace('Ekonomikum-', 'Obs/')}`;
    };

    const redrawPolygons = (map: Mazemap.Map): void => {
        const zLevel = map.getZLevel();
        let filteredFreeFeatures: TFeature[] = FREE_FEATURES.filter((feature: TFeature) => feature.properties.zLevel === zLevel);
        let filteredSemiFreeFeatures: TFeature[] = SEMI_FREE_FEATURES.filter((feature: TFeature) => feature.properties.zLevel === zLevel);
        let filteredBusyFeatures: TFeature[] = BUSY_FEATURES.filter((feature: TFeature) => feature.properties.zLevel === zLevel);

        const busyFilter = filteredBusyFeatures.map(f => {
            return f.properties.id;
        });
        filteredFreeFeatures = filteredFreeFeatures.filter(f => !busyFilter.includes(f.properties.id));

        const semiFreeFilter = filteredSemiFreeFeatures.map(f => {
            return f.properties.id;
        });
        filteredFreeFeatures = filteredFreeFeatures.filter(f => !semiFreeFilter.includes(f.properties.id));

        if (map.getSource('free-polygon-layer') === undefined) {
            return;
        }

        map.getSource('free-polygon-layer').setData({
            type: 'FeatureCollection',
            features: filteredFreeFeatures
        });

        map.getSource('semi-free-polygon-layer').setData({
            type: 'FeatureCollection',
            features: filteredSemiFreeFeatures
        });

        map.getSource('busy-polygon-layer').setData({
            type: 'FeatureCollection',
            features: filteredBusyFeatures
        });
    };

    const resetHighlightInfo = (map: Mazemap.Map): void => {
        if (map.highlighter !== undefined) {
            map.highlighter.clear();
        }

        renderElement('info-container', '');
    };

    const mazeMapElement = (): HTMLElement => {
        let el = document.getElementById('mazemap-root');
        if (el === null) {
            el = document.createElement('div');
            el.setAttribute('id', 'mazemap-root');
        }
        return el;
    };

    const buildingsNameUtil = (): void => {
        Mazemap.Data.getBuildingsByCampusId(CAMPUS_ID).then((buildings: TFeature[]) => {
            buildings.forEach((building: TFeature) => {
                //console.log(`Id ${building.properties.id} Name ${building.properties.name}`);
            });
        });
    };

    const poiInfo = async (map: Mazemap.Map, event: any) => {
        onProgressHandler(false);
        const poi = await Mazemap.Data.getPoiAt(event.lngLat, map.zLevel);
        if (poi?.geometry?.type === 'Polygon' && poi?.properties.names.some((n: string) => POI_TYPE_FILTER.includes(n))) {
            map?.highlighter.highlight(poi);
            renderPoiInfo(poi);
        }
        onProgressHandler(true);
    };

    const renderPoiInfo = async (poi: TPoi) => {
        const start = moment().tz(timezone).set({
            hour: 0,
            minute: 0,
            second: 0
        }).format('YYYY-MM-DD[T]HH:mm:ssZ');
        const end = moment().tz(timezone).set({
            hour: 23,
            minute: 59,
            second: 59
        }).format('YYYY-MM-DD[T]HH:mm:ssZ');

        const response = await fetchBooking(bookingType(poi), poi, start, end);
        if (response?.data && response.data.length === 0) {
            const s = `<h5 class="mt-3">${poi.properties.title}</h5><table class="table"><thead class="thead-light">
                 <tr><th>${moment().tz(timezone).format('dddd D MMMM YYYY')}</th></tr></thead>
                 <tbody><tr><td>${t('Inga bokningar idag')}</td></tr></tbody></table>`;
            renderElement('info-container', s);
        } else {
            const bookings: TBooking[] = response?.data.sort((a: any, b: any) => {
                return cmp(a.start, b.start)
            });

            let isThead = true;
            let s = '';
            bookings?.forEach(booking => {
                if (isThead) {
                    s += `<h5 class="mt-3">${poi.properties.title}</h5>
                     <div id="room-details">`;

                    if (booking.room?.details.size) {
                        s += `${t('Platser')} ${booking.room?.details.size}`;
                    }

                    s += `</div><table class="table"><thead class="thead-light">
                      <tr><th colspan="2">${moment().tz(timezone).format('dddd DD MMMM YYYY')}</th></tr></thead><tbody>`;
                    isThead = false;
                }

                if (moment(booking.start).tz(timezone).isSame(moment().tz(timezone), 'day')) {
                    s += `<tr><td class="no-wrap">${moment(booking.start).tz(timezone).format('HH:mm')} - 
                         ${moment(booking.end).tz(timezone).format('HH:mm')}</td><td>${booking.title}</td></tr>`;
                }
            });

            s += '</tbody></table>';

            if (bookings && bookings.length > 0) {
                const [booking] = bookings;
                if (booking.room?.details.url) {
                    s += `<div id="qr-code"><img id="qr-code-img" alt="QRcode" src="" />`;
                    s += `<a href="${booking.room.details.url}">use.mazemap.com</a></div>`;
                    createQRcode(booking.room.details.url).then(url => {
                        const el = document.getElementById('qr-code-img') as HTMLImageElement;
                        if (url && el != null) {
                            el.src = url;
                        }
                    });
                }
            }

            renderElement('info-container', `${s}`);
        }
    };

    const renderElement = (id: string, html: string) => {
        const el = document.getElementById(id);
        if (el !== null) {
            el.innerHTML = html;
        }
    };

    const createQRcode = async (text: string) => {
        try {
            return await QRCode.toDataURL(text && text.replace(' Zoom-Rum', ''), {errorCorrectionLevel: 'L'});
        } catch (e) {
            // Ignore
        }
        return '';
    };

    const cmp = (x: string, y: string) => {
        return x > y ? 1 : x < y ? -1 : 0;
    };

    const mazeMapInstance = (zLevel: number = 1): Mazemap.Map => {
        moment.locale('sv');

        const bounds = [
            BOUNDS_SW,
            BOUNDS_NE
        ];

        // noinspection TypeScriptValidateJSTypes
        const map = new Mazemap.Map({
            container: mazeMapElement(),
            campuses: CAMPUS_ID,
            zLevelUpdater: true,
            zLevelControl: true,
            bearing: 0,
            minZoom: 17,
            maxZoom: 19,
            maxBounds: bounds,
            showZoom: true,
            touchZoomRotate: false,
            doubleClickZoom: false,
            scrollZoom: true,
            zLevel: zLevel
        });

        map.jumpTo({
            center: JUMP_TO_CENTER,
            zoom: JUMP_TO_ZOOM
        });

        map.on('drag', function () {
            resetHighlightInfo(map);
        });

        map.on('load', function () {
            map.highlighter = new Mazemap.Highlighter(map, {
                showOutline: true,
                showFill: true,
                outlineColor: '#000',
                fillColor: '#000'
            });

            map.addLayer({
                id: 'free-polygon-layer',
                type: 'fill',
                source: {
                    type: 'geojson',
                    data: null,
                },
                paint: {
                    'fill-color': '#b1dfbb',
                    'fill-outline-color': '#b1dfbb'
                }
            }, POI_LABEL_LAYER_ID);

            map.addLayer({
                id: 'semi-free-polygon-layer',
                type: 'fill',
                source: {
                    type: 'geojson',
                    data: null,
                },
                paint: {
                    'fill-color': '#ffc107',
                    'fill-outline-color': '#ffc107'
                }
            }, POI_LABEL_LAYER_ID);

            map.addLayer({
                id: 'busy-polygon-layer',
                type: 'fill',
                source: {
                    type: 'geojson',
                    data: null,
                },
                paint: {
                    'fill-color': '#e2b8b8',
                    'fill-outline-color': '#e2b8b8'
                }
            }, POI_LABEL_LAYER_ID);

            map.addControl(new Mazemap.mapboxgl.NavigationControl());

            fetchPois(CAMPUS_ID, BUILDING_ID, map.zLevel).then((pois: TPoi[]) => {
                renderFreeBusy(map, pois);
            });
        });

        map.on('zlevel', function () {
            resetHighlightInfo(map);
            redrawPolygons(map);
            fetchPois(CAMPUS_ID, BUILDING_ID, map.zLevel).then((pois: TPoi[]) => {
                renderFreeBusy(map, pois);
            });
        });

        map.on('click', function (event: any) {
            resetHighlightInfo(map);
            poiInfo(map, event);
        });

        map.on('mouseenter', 'free-polygon-layer', () => map.getCanvas().style.cursor = 'pointer');
        map.on('mouseleave', 'free-polygon-layer', () => map.getCanvas().style.cursor = 'default');
        map.on('mouseenter', 'semi-free-polygon-layer', () => map.getCanvas().style.cursor = 'pointer');
        map.on('mouseleave', 'semi-free-polygon-layer', () => map.getCanvas().style.cursor = 'default');
        map.on('mouseenter', 'busy-polygon-layer', () => map.getCanvas().style.cursor = 'pointer');
        map.on('mouseleave', 'busy-polygon-layer', () => map.getCanvas().style.cursor = 'default');

        return map;
    };

    let mazeMapRef = useRef(mazeMapInstance());
    const mapRef = useCallback(el => {
        el && el.appendChild(mazeMapRef.current.getContainer());
        mazeMapRef.current.resize();
    }, [mazeMapRef]);

    useEffect(() => {
        const findPoiByPoiIdentifier = async (identifier: string | undefined): Promise<TPoi | undefined> => {
            if (identifier === undefined || identifier === '') {
                return Promise.resolve(undefined);
            }

            const pois = await fetchPois(CAMPUS_ID, BUILDING_ID, mazeMapRef.current.zLevel);
            return pois.find((p: TPoi) => {
                return p.properties?.identifier?.toLowerCase() === createIdentifier(identifier).toLowerCase()
            });
        };

        const renderMarker = (poi: TPoi | undefined): number | undefined => {
            if (poi === undefined) {
                return;
            }

            const lngLat = Mazemap.Util.getPoiLngLat(poi);
            const zLevel = parseInt(poi.properties.zLevel);

            mazeMapRef.current = mazeMapInstance(zLevel);
            renderElement('info-container', '');

            return window.setTimeout(async () => {
                await renderPoiInfo(poi);
                Mazemap.MazeMarker({zLevel: zLevel, color: '#b01c2e'}).setLngLat(lngLat).addTo(mazeMapRef.current);
                mazeMapRef.current.setZLevel(zLevel);
                mazeMapRef.current.panTo(lngLat, {
                    animate: true,
                    duration: 1.0
                })
            }, 3000);
        };

        let timeout: number | undefined;
        (async () => {
            const poi = await findPoiByPoiIdentifier(poiIdentifier);
            timeout = renderMarker(poi);
        })();

        return () => window.clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [poiIdentifier]);


    return (
        <>
            {isError && <div className="alert alert-danger" role="alert">{t('Felmeddelande')}</div>}
            <div id="freebusy-container">
                <div className="free-info"/>
                {t('Ledigt i 60 min')}
                <div className="semi-free-info"/>
                {t('Ledigt i mindre an 60 min')}
                <div className="busy-info"/>
                {t('Upptaget')}
            </div>
            <div id="mazemap-container" ref={mapRef}/>
            <div id="info-container"/>
        </>
    );
};

export default memo(MazeMapWrapper);