import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {cleanName, formatTime} from './Utils';
import {optionsList} from "./Definitions";

interface IBookingListItemProps {
    id: string;
    reservationId: string;
    location: string;
    start: string;
    end: string;
    title: string;
}

const BookingListItem: FC<IBookingListItemProps> = ({id, location, start, end, title}) => {
    const s = id.replace('/', '-');
    const headerLink = optionsList.some(e => e.value.toLowerCase() === id.toLowerCase()) ?
        <Link to={`/hitta-lokal/${s.toLowerCase()}`}><strong>{cleanName(location)}</strong></Link> :
        <strong>{cleanName(location)}</strong>;

    return (
        <tr>
            <td className={"col-sm-3 no-wrap"}>{headerLink}</td>
            <td className={"col-sm-2 no-wrap"}>{formatTime(start)}-{formatTime(end)}</td>
            <td className={"col-sm-7"}>{title}</td>
        </tr>
    );
};

export default BookingListItem;