import React, {FC, useState, useEffect} from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Select, {MultiValue} from 'react-select'
import moment from 'moment-timezone';
import 'moment/locale/sv';
import 'moment/locale/en-gb';
import 'react-day-picker/lib/style.css';
import './tomorrow.css';
import 'react-placeholder/lib/reactPlaceholder.css';
import BookingList from '../Shared/BookingList';
import {formatDate, groupByCriteria} from '../Shared/Utils';
import {
    campus,
    customPlaceholder,
    customStyles,
    customTheme,
    IGroupByBooking,
    IProps,
    optionsList,
    TBookingList,
    timezone
} from '../Shared/Definitions';
import axios, {AxiosError} from 'axios';
import {useTranslation} from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import {useLocation, useHistory} from 'react-router-dom';
import MomentLocaleUtils from 'react-day-picker/moment';
import CustomMultiValue from './CustomMultiValue';

const Tomorrow: FC<IProps> = ({onProgressHandler}) => {
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const {t, i18n} = useTranslation();
    i18n.resolvedLanguage === 'en' ? moment.locale('en-gb') : moment.locale('sv');

    let fromDayPicker: DayPickerInput | null;
    const criteria = 'start';
    //const placeHolder = `${moment().format('YYYY-MM-DD')}`;
    const [isError, setIsError] = useState(false);
    const [groupByList, setGroupByList] = useState<IGroupByBooking[]>([]);
    const [rooms, setRooms] = useState<MultiValue<any>>([]);
    const [fromDate, setFromDate] = useState<any>();
    const [toDate, setToDate] = useState<any>();
    const [hasFinished, setHasFinished] = useState<boolean>(true);
    const modifiers = {start: fromDate, end: toDate};
    const today = new Date();

    const fetchBookings = () => {
        try {
            setHasFinished((prevValue) => !prevValue);
            onProgressHandler(false);

            const url = `${process.env.REACT_APP_API_URL}${queryParams.get('all') === 'true' ? 'bookings-w-labs' : 'bookings'}`;
            const start: string | undefined = formatDate(fromDate, 'YYYY-MM-DD[T]HH:mm:ssZ')?.trim();
            const end: string | undefined = formatDate(toDate, 'YYYY-MM-DD[T]HH:mm:ssZ')?.trim();
            let paramsId = '';
            rooms.forEach((value, index: number) => {
                paramsId += value.value;
                paramsId += rooms.length - 1 !== index ? ';' : '';
            });

            const params = {params: {id: paramsId, start: start, end: end}};
            return axios.get<TBookingList>(url, params).catch((e:AxiosError) => {
                setIsError(true);
            });
        } catch (e) {
            setIsError(true);
        }
    };

    const onDayChangeFromHandler = (day: Date): void => {
        if (moment(day || null, true).isValid()) {
            setFromDate(day);
        } else {
            setFromDate(() => undefined);
        }
    };

    const onDayChangeToHandler = (day: Date): void => {
        if (moment(day || null, true).isValid()) {
            setToDate(day);
        } else {
            setToDate(() => undefined);
        }
    };

    const onChangeHandler = (e: MultiValue<any>): void => {
        setRooms(e);
    };

    const parseDate = (s: string): (void | Date) => {
        if (moment(s || null, ['YYYY-MM-DD'], true).isValid()) {
            return moment(s).tz(timezone).toDate();
        }
    };

    const onClickHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        const isValidFrom = moment(fromDate || null, true).isValid();
        const isValidTo = moment(toDate || null, true).isValid();
        if (rooms.length === 0 || !isValidFrom || !isValidTo || moment(fromDate).isAfter(toDate)) {
            setGroupByList(() => {
                return [];
            });
            return;
        }

        const response = await fetchBookings();
        setGroupByList(groupByCriteria(response?.data, criteria));

        setHasFinished((prevValue) => !prevValue);
        onProgressHandler(true);
    };

    useEffect(() => {
        setTimeout(() => {
            history.push('/');
        }, 1800000);
    });

    return (
        <form className="inline-form">
            <h1>{t('Bokningar andra dagar')}</h1>
            {isError && <div className="alert alert-danger" role="alert">{t('Felmeddelande')}</div>}
            <div className="row">
                <div className="col-lg-4 col-sm-12 mb-2">
                    <label htmlFor="select-room">{t('Lokal')}</label>
                    <Select inputId='select-room'
                            className='select-room'
                            isMulti value={rooms}
                            aria-labelledby='select-room'
                            isOptionDisabled={(option) => (rooms.length >= 2) || (rooms.some(v => v.value === campus)) || (rooms.length === 1 && option.value === campus)}
                            placeholder=''
                            onChange={onChangeHandler} theme={customTheme}
                            styles={customStyles}
                            options={optionsList}
                            components={{MultiValue: CustomMultiValue}}/>
                </div>
                <div className="col-lg-3 col-sm-6 mb-2">
                    <label htmlFor="input-from-to">{t('Startdatum')}</label>
                    <div id="input-from-to" className="InputFromTo">
                        <DayPickerInput
                            inputProps={{'aria-label': 'input-from-to', 'id': 'input-from-to'}}
                            value={fromDate}
                            placeholder=''
                            format='YYYY-MM-DD'
                            formatDate={formatDate}
                            parseDate={parseDate}
                            dayPickerProps={{
                                localeUtils: MomentLocaleUtils,
                                todayButton: i18n.t('Idag'),
                                showWeekNumbers: true,
                                locale: i18n.resolvedLanguage,
                                firstDayOfWeek: 1,
                                month: today,
                                fromMonth: today,
                                selectedDays: [fromDate, {from: fromDate, to: toDate}],
                                disabledDays: {before: today, after: toDate},
                                toMonth: toDate,
                                modifiers,
                                numberOfMonths: 1,
                                onDayClick: () => fromDayPicker?.getInput().focus()
                            }}
                            onDayChange={onDayChangeFromHandler}
                        />
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 mb-2">
                    <label htmlFor="input-from-to-to">{t('Slutdatum')}</label>
                    <div id="input-from-to-to" className="InputFromTo-to">
                        <DayPickerInput
                            inputProps={{'aria-label': 'input-from-to-to', 'id': 'input-from-to-to'}}
                            ref={el => (fromDayPicker = el)}
                            value={toDate}
                            placeholder=''
                            format='YYYY-MM-DD'
                            formatDate={formatDate}
                            parseDate={parseDate}
                            dayPickerProps={{
                                localeUtils: MomentLocaleUtils,
                                todayButton: i18n.t('Idag'),
                                showWeekNumbers: true,
                                locale: i18n.resolvedLanguage,
                                firstDayOfWeek: 1,
                                selectedDays: [fromDate, {from: fromDate, to: toDate}],
                                disabledDays: {before: today},
                                modifiers,
                                month: fromDate,
                                fromMonth: fromDate,
                                numberOfMonths: 1
                            }}
                            onDayChange={onDayChangeToHandler}
                        />
                    </div>
                </div>
                <div className="col-lg-2 col-sm-12 mb-2 align-self-end">
                    <button className="s-button" onClick={onClickHandler}>{t('Visa')}</button>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-lg-12 col-sm-12 mt-2"}>
                    <ReactPlaceholder showLoadingAnimation customPlaceholder={customPlaceholder} ready={hasFinished}>
                        <BookingList groupByList={groupByList}/>
                    </ReactPlaceholder>
                </div>
            </div>
        </form>
    );
};

export default Tomorrow;