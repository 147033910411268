import React, {FC, Fragment, useRef} from 'react';
import Footer from './Footer';
import Main from './Main';
import Header from './Header';
import LoadingBar from 'react-top-loading-bar';

const Layout: FC = () => {
    const loadingBarRef = useRef(null);
    const onProgressHandler = (hasFinished: boolean): void => {
        // @ts-ignore
        hasFinished ? loadingBarRef.current.complete() : loadingBarRef.current.continuousStart();
    };

    return (
        <Fragment>
            <LoadingBar color="#b01c2e" ref={loadingBarRef}/>
            <Header/>
            <Main onProgressHandler={onProgressHandler}/>
            <Footer/>
        </Fragment>
    );
};

export default Layout;