import React, {FC, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import CookieConsent from 'react-cookie-consent';
import ScrollToTop from 'react-scroll-to-top';
import {isKiosk} from '../containers/Shared/Utils';

const Footer: FC = () => {
    const {t} = useTranslation();
    const [kiosk, setKiosk] = useState<boolean>(false);

    useEffect(() => {
        (async () => setKiosk(await isKiosk()))();
    });

    return (
        <>
            <ScrollToTop id="scroll-button"
                         viewBox="4 4 24 24"
                         svgPath="M25.724 22.276l-9.724-9.723-9.724 9.723-1.884-1.884 11.609-11.61 11.609 11.61z"
                         style={{
                             opacity: 0.6,
                             width: '52px',
                             height: '52px',
                             marginBottom: '40px',
                             borderRadius: '50%',
                             color: '#fff',
                             backgroundColor: '#0d0d0d'
                         }}/>
            <footer className="layout-footer">
                <div className="layout-footer-bg-img"/>
                <div className="container">
                    <div className="row layout-footer-last-row">
                        <div className="col-12">
                            {kiosk ?
                                <p>© {t('Uppsala universitet')} | {t('Telefon')}: {t('Telefonnummer')} | {t('Box')}<br/> {t('Organisationsnummer')}:
                                    202100-2932 | {t('Momsregistreringsnummer')}: SE202100293201 | PIC: 999985029
                                    | {t('Registrator')} | {t('Om webbplatsen')} | {t('Dataskyddspolicy')} | {t('Sidansvarig')}:
                                    info@ekonomikum.uu.se
                                </p> :
                                <p>© {t('Uppsala universitet')} | {t('Telefon')}: {t('Telefonnummer')} | {t('Box')}<br/> {t('Organisationsnummer')}:
                                    202100-2932 | {t('Momsregistreringsnummer')}: SE202100293201 | PIC: 999985029 | <a
                                        href="https://www.uu.se/om-uu/kontakta-oss/registrator">{t('Registrator')}</a> | <a
                                        href="https://www.uu.se/om-webbplatsen">{t('Om webbplatsen')}</a> | <a
                                        href="https://www.uu.se/om-uu/dataskyddspolicy">{t('Dataskyddspolicy')}</a> | {t('Sidansvarig')}: <a
                                        href="mailto:info@ekonomikum.uu.se">info@ekonomikum.uu.se</a>
                                </p>

                            }
                        </div>
                    </div>
                </div>
            </footer>
            {kiosk ? <></> :
                <CookieConsent buttonText={t('Kakknapp')}
                               overlay={false}
                               debug={false}
                               location="bottom"
                               cookieName="hideCookieMessage"
                               expires={999}
                               acceptOnScroll={true}
                               acceptOnScrollPercentage={15}
                               disableStyles={false}
                               ariaAcceptLabel={t('Kakaria')}
                               style={{borderTop: '1px solid #eee', background: '#fff'}}
                               buttonStyle={{
                                   borderRadius: '4px',
                                   marginTop: '0',
                                   fontSize: '.7em',
                                   color: '#fff',
                                   backgroundColor: '#000'
                               }}
                ><span style={{fontSize: '.85em', color: '#000'}}>{t('Kaktext')} <a
                    href="https://www.uu.se/en/about-website">{t('Kakmertext')}</a></span></CookieConsent>
            }
        </>
    );
};

export default Footer;