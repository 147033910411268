import React, {FC, useEffect, useState} from 'react';
import {customStyles, customTheme, IProps, optionsList} from '../Shared/Definitions';
import MazeMapWrapper from '../Shared/MazeMapWrapper';
import Select, {ActionMeta, SingleValue} from 'react-select';
import {useHistory, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

type TParams = {
    id: string | undefined;
}

const Find: FC<IProps> = ({onProgressHandler}) => {
    const [, ...options] = optionsList;
    const params = useParams<TParams>();
    const history = useHistory();
    const initOption = {value: '', label: ''};
    const [selectedOption, setSelectedOption] = useState<SingleValue<{ value: string; label: string; }>>(initOption);
    const [poiIdentifier, setPoiIdentifier] = useState<string>('');
    const {t} = useTranslation();

    const onChangeHandler = (newValue: SingleValue<{ value: string; label: string; }>, actionMeta: ActionMeta<{ value: string; label: string; }>): void => {
        const value = newValue?.value as string;
        setSelectedOption({value: value, label: findLabel(value)});
    };

    const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        setPoiIdentifier(() => selectedOption?.value as string);
        history.replace(`/hitta-lokal/${selectedOption?.value.replace('/', '-')}`);
    };

    const findLabel = (value: string): string => {
        const option = options.find(e => e.value === value);
        if (option) {
            return option.label;
        }
        return '';
    };

    useEffect(() => {
        if (params.id) {
            const s = params.id.replace('-', '/');
            setSelectedOption({value: s, label: findLabel(s)});
            setPoiIdentifier(s);
            history.replace(`/hitta-lokal/${s.replace('/', '-')}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setTimeout(() => {
            history.push('/');
        }, 1800000);
    });

    return (
        <form className="inline-form">
            <h1>{t('Hitta lokal')} {selectedOption ? findLabel(selectedOption.value) : ''}</h1>
            <div className="row">
                <div className="col-12">
                    <label htmlFor="select-room">
                        {t('Lokal')}
                    </label>
                </div>
                <div className="col-lg-4 col-sm-12 mb-2">
                    <Select inputId='select-room' className={'select-room'}
                            placeholder={t('Lokal')}
                            onChange={onChangeHandler} theme={customTheme}
                            styles={customStyles}
                            options={options}
                            defaultValue={selectedOption}
                            value={selectedOption}
                    />
                </div>
                <div className="col-lg-8 col-sm-12 mb-2">
                    <button className="s-button" onClick={onClickHandler}>{t('Visa')}</button>
                </div>
            </div>
            <MazeMapWrapper poiIdentifier={poiIdentifier} onProgressHandler={onProgressHandler}/>
        </form>
    );
};

export default Find;