import moment from 'moment-timezone';
import {IBooking, IGroupByBooking, timezone, kioskIpList} from './Definitions';
import axios from 'axios';

export const formatDateAsString = (s: string, format: string = 'YYYY-MM-DD'): string => {
    if (!moment(s || null, true).isValid()) {
        return moment().tz(timezone).format(format);
    }

    return moment(s).tz(timezone).format(format);
};

export const formatDate = (date: Date | undefined, format: string = 'YYYY-MM-DD'): string => {
    if (!moment(date || null, true).isValid()) {
        return '';
    }

    return moment(date).tz(timezone).format(format);
};

export const formatTime = (date: string): string => {
    return moment(date).tz(timezone).format('HH:mm');
};

export const cleanName = (id: string): string => {
    let name = id && id.toLowerCase().replace('obs/', '').replace('+', '').replace('r_eko', '').replace('@user.uu.se', '').toUpperCase();
    if (name.indexOf('HÖRSAL') === -1) {
        return name.toUpperCase();
    }
    return name[0].toUpperCase() + name.slice(1).toLowerCase();
};

export const groupBy = (array: any, criteria: any): any[] => {
    return array.reduce((previousValue: any, currentValue: any) => {
        let s = formatDateAsString(currentValue[criteria]);
        if (!previousValue[s]) {
            previousValue[s] = [];
        }
        previousValue[s].push(currentValue);
        return previousValue;
    }, {});
};

export const groupByCriteria = (bookings: IBooking[] | undefined, criteria: string): IGroupByBooking[] => {
    if (bookings === undefined) {
        return [];
    }

    const groupByBookings: IGroupByBooking[] = [];
    for (const [key, value] of Object.entries(groupBy(sort(bookings, criteria), criteria))) {
        groupByBookings.push({
            criteria: key,
            bookings: value
        });
    }
    return groupByBookings;
};

export const sort = (bookings: IBooking[], criteria: string): IBooking[] => {
    return bookings.sort(function (b1, b2) {
        return b1[criteria].localeCompare(b2[criteria]);
    });
};

export const isKiosk = async () => {
    try{
        const response = await axios.get('https://api.ipify.org');
        return kioskIpList.includes(await response?.data);
    } catch (e) {
        return true;
    }
};