import {TextBlock} from 'react-placeholder/lib/placeholders';
import React from 'react';

export const campus = 'eko';
export const timezone = 'Europe/Berlin';

export interface IGroupByBooking {
    criteria: string;
    bookings: IBooking[]
}

export interface IBooking extends Record<string, any> {
    id: string;
    reservationId: string;
    location: string;
    title: string;
    start: string;
    end: string;
}

export type TBookingList = IBooking[]

export interface IProps {
    onProgressHandler: (hasFinished: boolean) => void;
}

export const customStyles = {
    control: (base: any, state: any) => {
        const limitReached = state.selectProps.value?.length >= 2;
        return {
            ...base,
            '.select-brand__dropdown-indicator': {
                display: `${limitReached ? "none" : "flex"}`
            },
            borderRadius: 0,
            border: '1px solid #999',
            height: '42.78px',
            borderColor: '#000',
            boxShadow: 'none',
            fontSize: '1rem'
        }
    }
};
export const customTheme = (theme: any) => ({
    ...theme,
    borderRadius: 0,
    colors: {
        ...theme.colors,
        primary25: '#eee',
        primary: 'black',
        primary75: '#eee',
        primary50: '#eee'
    }
});

export const optionsList = [
    {value: campus, label: 'Ekonomikum'},
    {value: 'obs/a114', label: 'A114'},
    {value: 'obs/a122', label: 'A122'},
    {value: 'obs/a138', label: 'A138'},
    {value: 'obs/a144', label: 'A144'},
    {value: 'obs/a156', label: 'A156'},
    {value: 'obs/a204', label: 'A204'},
    {value: 'obs/b105', label: 'B105'},
    {value: 'obs/b115', label: 'B115'},
    {value: 'obs/b125', label: 'B125'},
    {value: 'obs/b139', label: 'B139'},
    {value: 'obs/b153', label: 'B153'},
    {value: 'obs/b159', label: 'B159'},
    {value: 'obs/b163', label: 'B163'},
    {value: 'obs/b178', label: 'B178'},
    {value: 'obs/b359', label: 'B359'},
    {value: 'obs/b478', label: 'B478'},
    {value: 'obs/c208', label: 'Hörsal 1'},
    {value: 'obs/c212', label: 'Hörsal 2'},
    {value: 'obs/c214', label: 'Hörsal 3'},
    {value: 'obs/c225', label: 'Hörsal 4'},
    {value: 'obs/e203', label: 'E203'},
    {value: 'obs/f316', label: 'F316'},
    {value: 'obs/f321', label: 'F321'},
    {value: 'obs/f324', label: 'F324'},
    {value: 'obs/f329', label: 'F329'},
    {value: 'obs/f332', label: 'F332'},
    {value: 'obs/f416', label: 'F416'},
    {value: 'obs/f433', label: 'F433'},
    {value: 'obs/f434', label: 'F434'},
    {value: 'obs/g205', label: 'G205'},
    {value: 'obs/g207', label: 'G207'},
    {value: 'obs/g209', label: 'G209'},
    {value: 'obs/g211', label: 'G211'},
    {value: 'obs/g213', label: 'G213'},
    {value: 'obs/g215', label: 'G215'},
    {value: 'obs/g222', label: 'G222'},
    {value: 'obs/h317', label: 'H317'},
    {value: 'obs/h325', label: 'H325'},
    {value: 'obs/h326', label: 'H326'},
    {value: 'obs/h333', label: 'H333'},
    {value: 'obs/h425', label: 'H425'},
    {value: 'obs/h429', label: 'H429'},
    {value: 'obs/h432', label: 'H432'},
    {value: 'obs/k320', label: 'K320'},
    {value: 'obs/k334', label: 'K334'},
    {value: 'obs/k336', label: 'K336'},
    {value: 'obs/k412', label: 'K412'},
    {value: 'obs/k436', label: 'K436'},
    {value: 'obs/l240', label: 'L240'},
    {value: 'obs/l318', label: 'L318'}
];


export const kioskIpList = [
    '130.238.163.12', '130.238.163.13', '130.238.163.14', '130.238.163.15', '130.238.162.167'
];

export const customPlaceholder = (
    <div className='custom-placeholder'>
        <TextBlock lineSpacing={25} widths={[100]} rows={100} color='#e4e5e4'/>
    </div>
);